import React from 'react';

const dynamic_html = (props) => { 
    let {html} = props

     
    return ( 
        <div className={props.className} dangerouslySetInnerHTML={{__html: html}}>     
        </div>
    ); 
}

export default dynamic_html;
