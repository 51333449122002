import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Span from '../../../../prolon-theme/components/general/dynamic_html';
const StyledDiv = styled.div`
  width: 100%;
  min-width: 160px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: all 1s ease;
  background: white;
  height: 520px;
  background-image: ${(props) => `url(${props.bgImage})`};
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  @media (max-width: 767px) {
    background-image: ${(props) => `url(${props.bgImageMobile})`};
   
    height: 420px;
  }
  .gridItem {
    position: relative;
    text-align: right;
    color: black;
  }
  @media only screen and (max-width: 767px) {
    .gridItem {
      text-align: top;  
    }
    .content-block {
      top: 0px;
      left: 0px;
    }
  }
  .image-div {
    width: 100%;
    max-width: 100%;
  } 

  .content-block {
    background: transparent;
    width: 100%;
    max-width: 90%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .offSale {
    text-transform: uppercase;
    letter-spacing: 0.3em;
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 12px;
    text-align: center;
    font-family: 'Harmonia Sans', 'Nunito Sans', Arial, sans-serif;
    color: #002500;
    text-align: right;
    margin-left: auto;
  }
  .afterSale {
    font-size: 50px; 
    font-family: 'Harmonia Sans', 'Nunito Sans', Arial, sans-serif;
    font-weight: 600; 
    color: #002500;
    margin-bottom: 12px;    display: block;
    text-align: right;
    width: 100%;
  }

  .fasting {
    font-size: 1.25em;
    color: #002500;
    text-align: right;
    margin-bottom: 16px;
    line-height: 1.6;
    font-weight: 400;
    font-family: 'Harmonia Sans', 'Nunito Sans', Arial, sans-serif;
  } 
  .orderNowButton {
    background:var(--colorBtnPrimary);
    border: none !important;
    line-height: 1.42;
    text-decoration: none;
    text-align: center;
    white-space: normal;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    display: inline-block;
    padding: 11px 20px;
    width: auto;
    min-width: 90px;
    cursor: pointer;
    margin-left: auto;
    font-size: 13px;
    color:white;
  }
  .orderSpan {
    color: #ffffff;
    line-height: 1.42;
    text-align: center;
    white-space: normal;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }

  @media (max-width: 767px) {
    .content-block{
      margin:0 auto;    margin-top: -100px;

    }
    .orderSpan,
    .offSale {
      font-size: 14px;
      text-align: center;
      margin:0 auto;
    }
    .afterSale {
      font-size: 2.25em;      text-align: center;
      margin:0 auto;
 
    }
    .fasting {
      text-align: center;
      font-size: 0.85em;
      margin:0 auto;
      margin-bottom: 20px;
    }
    .orderNowButton{
      font-size: 11px;
      text-align: center;
      margin:0 auto;
    }
  }
`;

const PromoGrid = ({data,page}) => {
   
    let finalData= data?.schema_json;  
  
      let content=  {
          title:'New Year, Fresh Start',
          subTitle:'A 5-DAY FAST, WITH FOOD!', 
          description:`<div class="row" style="max-width: 670px; font-size: .9em; margin: auto auto 0 0px;">Kick start your health goals with a <strong>5-day fast with food! </strong>Scientifically formulated meal plan to provide nourishment while keeping your body in a fasting state. No guess work, no planning, a fresh way to fast.</div>`,
          backgroundColor:'#f3fae8',
          backgroundImage:'https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_-_Fresh_Start_1528x.png?v=1609961075', 
          backgroundImageMobile:'https://cdn.shopify.com/s/files/1/0126/2921/3243/files/ProLon_Mobile_Hero_-_Fresh_Start_1944x.png?v=1609962116', 
          buttons:[
            {
              id: 1,
              text: `ORDER NOW + FREE SHIPPING`,
              href: '/products/buy-prolon', 
              style:{
                    backgroundColor: '#ff6353',
                    textColor:'#fff'
                    }
            }
          ], 
        ...finalData
      }
  return (
    <StyledDiv
      bgImage={content?.backgroundImage}
      bgImageMobile={content.backgroundImageMobile}
    >
      <div className="gridItem">
        <div className="content-block">
          <div className="offSale">
            <Span html={content.subTitle} />
          </div>
          <div class="afterSale">
            <Span html={content.title} />
          </div>

          <Span className="fasting" html={content.description} />

          {content.buttons &&
            content.buttons.map((e) => {
              return (
                <Link to={e.href} class="orderNowButton" style={content.style}>
                  {e.text} 
                </Link>
              );
            })}
        </div>
      </div>
    </StyledDiv>
  );
};

export default PromoGrid;
